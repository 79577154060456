import { LoginResponse } from "../Models/LoginResponse";
import { IAuthorizationService, IHttpService } from './Interfaces/Interfaces';

export class AuthorizationService implements IAuthorizationService {
    private readonly httpService : IHttpService;

    constructor(httpService : IHttpService) {
        this.httpService = httpService;
    }

    logInAndGetToken(passcode : string) {
        return this.httpService.httpGet<LoginResponse>(`login/${passcode}`);
    }
}