import { RepetitionType } from "../Enums/RepetitionType";
import { v4 as uuidv4 } from 'uuid';

export class CalendarEvent {
    constructor(startDate? : Date) {
        if (startDate) {
            this.StartDate = startDate.toLocaleDateString();
        }

        this.Guid = uuidv4();
    }
    CalendarEventId : number = 0;
    Guid : string = '';
    Name : string = '';
    StartDate : string = '';
    EndDate : string | null = null;
    Pattern : string = '';
    RepetitionTypeId : number = 0;
    RepetitionType : RepetitionType = RepetitionType.None;
    IsOverride : boolean = false;
}