import { AsyncServerResponse } from "../Models/AsyncServerResponse";
import { ServerResponse } from "../Models/ServerResponse";
import { Settings } from "../Settings/Settings";
import { IHttpService } from "./Interfaces/Interfaces";

export class HttpService implements IHttpService {
    private token : string | null = null;

    httpGet<T>(relativeUrl : string) : AsyncServerResponse<T> {
        return this.doHttp(relativeUrl);
    }

    httpPost<T>(relativeUrl : string, data : any | undefined) : AsyncServerResponse<T> {
        const requestOptions : RequestInit = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: data ? JSON.stringify(data) : null
        };

        return this.doHttp(relativeUrl, data, requestOptions);
    }

    setToken(token : string | null) {
        this.token = token;
    }

    private doHttp<T>(relativeUrl : string, data? : any, requestOptions? : RequestInit) : AsyncServerResponse<T> {
        if (this.token) {
            if (!requestOptions) {
                requestOptions = {};
            }
            if (!requestOptions.headers) {
                requestOptions.headers = {};
            }
        
            requestOptions.headers = {
                ...requestOptions.headers,
                token: this.token
            }
        }

        return fetch(Settings.API_ROOT_URL + relativeUrl, requestOptions)
            .then(x => {
                if (x.ok) {
                    return x.json()
                }

                let response = new ServerResponse<T>();
                response.IsSuccess = false;

                if (x.status === 401) {
                    response.Message = 'Your session has expired. Reloading...'
                    window.location.reload();
                }

                return response;  
            });
    }
}