import { Component, MouseEvent, ChangeEvent } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { CalendarEvent } from '../../Models/CalendarEvent';
import Button from '@material-ui/core/Button';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import ClearIcon from "@material-ui/icons/Clear";
import { IconButton } from "@material-ui/core";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { AlertDialog } from '../../Components/AlertDialog/AlertDialog';
import './EventDialog.css';
import { ICalendarService } from '../../Services/Interfaces/Interfaces';
import { AlertDialogResponse } from '../../Enums/AlertDialogResponse';
import { RepetitionType } from '../../Enums/RepetitionType';

class EventDialogState {
    emojiPattern : string = '';
    isBusy : boolean = false;
    event : CalendarEvent = new CalendarEvent();
    isConfirmDialogOpen = false;
    isAlertDialogOpen = false;
    validationMessages : string[] = [];
}

class EventDialogProps {
    isOpen = false;
    allEvents : CalendarEvent[] = [];
    event : CalendarEvent | null = null;

    onClose = () => {};
    onSave : (event : CalendarEvent) => void = () => {};
    onDelete : (event : CalendarEvent) => void = () => {};

    calendarService : ICalendarService;

    constructor(calendarService : ICalendarService) {
        this.calendarService = calendarService;
    }
}

export class EventDialog extends Component<EventDialogProps, EventDialogState> {
    constructor(props : EventDialogProps) {
        super(props);

        let state = new EventDialogState();
        state.event = props.event ?? new CalendarEvent();
        this.state = state;
    }

    render() {
        if (!this.state.event) {
            return null;
        }

        const format = 'MM/dd/yyyy';
        const inputVariant = 'outlined';
        
        return (
            <div>
                <Dialog className="EventDialog" open={this.props.isOpen} onClose={this.handleCancel} disableBackdropClick>
                    <DialogContent>
                        <div className="eventForm">
                            <div>
                                <TextField
                                    variant={inputVariant}
                                    autoFocus
                                    name="eventName"
                                    id="eventName"
                                    label="Event Name"
                                    fullWidth={true}
                                    value={this.state.event.Name}
                                    disabled={this.state.isBusy}
                                    onChange={this.handleNameChange}
                                />
                            </div>
                            <div>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div>
                                        <DatePicker
                                            disableToolbar
                                            inputVariant={inputVariant}
                                            variant="inline"
                                            format={format}
                                            disabled={this.state.isBusy}
                                            margin="normal"
                                            id="start-date"
                                            label="Start Date"
                                            value={this.state.event.StartDate}
                                            onChange={this.handleStartDateChange}
                                            autoOk={true}
                                            fullWidth={true}
                                        />
                                    </div>
                                    <div>
                                        <DatePicker
                                            disableToolbar
                                            variant="inline"
                                            inputVariant={inputVariant}
                                            disabled={this.state.isBusy}
                                            format={format}
                                            margin="normal"
                                            id="end-date"
                                            label="End Date"
                                            value={this.state.event.EndDate}
                                            onChange={this.handleEndDateChange}
                                            autoOk={true}
                                            fullWidth={true}
                                            minDate={this.state.event.StartDate}
                                            InputProps={{
                                            endAdornment: (
                                                <IconButton onClick={this.handleClearDate} disabled={this.state.isBusy} size="small">
                                                    <ClearIcon />
                                                </IconButton>
                                            )}}
                                        />                                
                                    </div>
                                </MuiPickersUtilsProvider>
                            </div>
                            <div>
                                <FormControl fullWidth={true} variant={inputVariant}>
                                    <InputLabel htmlFor="override">Override</InputLabel>
                                    <Select
                                    fullWidth={true}
                                    value={+this.state.event.IsOverride}
                                    onChange={this.handleIsOverrideChange}
                                    disabled={this.state.isBusy}
                                    inputProps={{
                                        name: 'override',
                                        id: 'override',
                                    }}
                                    label="Override"
                                    >
                                        <MenuItem value={1}>Yes</MenuItem>
                                        <MenuItem value={0}>No</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div>
                                <FormControl fullWidth={true} variant={inputVariant}>
                                    <InputLabel htmlFor="repetition-type">Repetition Type</InputLabel>
                                    <Select
                                        variant={inputVariant}
                                        fullWidth={true}
                                        value={this.state.event.RepetitionTypeId ?? 0}
                                        disabled={!this.state.event.IsOverride || this.state.isBusy}
                                        onChange={this.handleRepetitionTypeChange}
                                        inputProps={{
                                            name: 'repetition-type',
                                            id: 'repetition-type',
                                        }}
                                        label="Repetition Type"
                                        >
                                        <MenuItem value={0}>None</MenuItem>
                                        <MenuItem value={1}>Annual by Date</MenuItem>
                                        <MenuItem value={2}>Annual by Day of Week</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div>
                                <TextField
                                    multiline
                                    variant={inputVariant}
                                    name="pattern"
                                    id="pattern"
                                    label="Pattern"
                                    fullWidth={true}
                                    value={this.emojify(this.state.event.Pattern)}
                                    disabled={this.state.isBusy}
                                    onChange={this.handlePatternChange}
                                    inputProps={{ 
                                        style: { 
                                            textAlign: 'center' 
                                        },
                                    }}
                                />
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleSave} variant="contained" disabled={this.state.isBusy} color="secondary">
                            OK
                        </Button>
                        {this.props.allEvents.find(x => this.props.event && this.props.calendarService.getUniqueCalendarEventId(x) === this.props.calendarService.getUniqueCalendarEventId(this.props.event)) &&
                            <Button onClick={this.handleDelete} disabled={this.state.isBusy} variant="contained">
                                Delete
                            </Button>
                        }
                        <Button onClick={this.handleCancel} disabled={this.state.isBusy} variant="contained">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
                
                <AlertDialog messages={this.state.validationMessages} isOpen={this.state.isAlertDialogOpen} onClose={this.handleAlertClosed} />
                <AlertDialog message="Are you sure you want to delete this event?" isOpen={this.state.isConfirmDialogOpen} isConfirm={true} onClose={this.handleConfirmDeleteClosed} />
            </div>
        );
    }
    
    handleConfirmDeleteClosed = (result? : AlertDialogResponse) => {
        if (result === AlertDialogResponse.OK) {
            this.props.onDelete(this.state.event);
        }

        this.setState({
            isConfirmDialogOpen: false
        })
    }
    
    handleAlertClosed = () => {
        this.setState({
            isAlertDialogOpen: false
        });
    }

    handleClearDate = (e : MouseEvent) => {
        e.stopPropagation();
        this.handleEndDateChange(null);
    }

    handleDelete = () => {
        this.setState({
            isConfirmDialogOpen: true
        });
    }

    handleSave = () => {
        this.setState({
            isBusy: true
        });

        let events = JSON.parse(JSON.stringify(this.props.allEvents)) as CalendarEvent[];

        let matchingEventIdx = events.findIndex(x => this.props.calendarService.eventMatches(x, this.state.event));

        if (matchingEventIdx >= 0) {
          events.splice(matchingEventIdx, 1);
        }
    
        events.push(this.state.event);

        this.props.calendarService.validateCalendarEvents(events).then(result => {
            let foundValidationResult = result.Data?.ValidationResults && result.Data.ValidationResults.find(x => (this.state.event.Guid && x.CalendarEventGuid === this.state.event.Guid) || (this.state.event.CalendarEventId && x.CalendarEventId === this.state.event.CalendarEventId))
            if (!result.IsSuccess && foundValidationResult) {
                let messages : string[] = [];
                foundValidationResult.ValidationErrors.forEach(x => {
                    messages.push(x.Message);
                });

                this.setState({
                    isAlertDialogOpen: true,
                    validationMessages: messages,
                });
            }
            else {
                this.props.onSave(this.state.event);
            }

            this.setState({
                isBusy: false
            });        
        });
    }

    handleCancel = () => {
        this.props.onClose();
    }

    handleIsOverrideChange = (e : ChangeEvent<any>) => {
        const isOverride = !!+e.target.value;
        const repetitionType = isOverride ? this.state.event.RepetitionType : RepetitionType.None;
        this.setState({
            event: {
                ...this.state.event,
                IsOverride: isOverride,
                RepetitionTypeId: +repetitionType,
                RepetitionType: repetitionType
            }
        });
    }

    handleRepetitionTypeChange = (e : ChangeEvent<any>) => {
        this.setState({
            event: {
                ...this.state.event,
                RepetitionTypeId: +e.target.value,
                RepetitionType: +e.target.value,
            }
        });
    }

    handlePatternChange = (e : ChangeEvent<HTMLInputElement>) => {
        this.setState({
            emojiPattern: e.target.value,
            event: {
                ...this.state.event,
                Pattern: this.demogify(e.target.value),
            }
        });
    }

    handleNameChange = (e : ChangeEvent<HTMLInputElement>) => {
        this.setState({
            event: {
                ...this.state.event,
                Name: e.target.value
            }
        });
    }

    handleStartDateChange = (date : MaterialUiPickersDate) => {
        if (date === null) {
            return;
        }
        this.setState({
            event: {
                ...this.state.event,
                StartDate: date.toLocaleDateString()
            }
        });
    }

    handleEndDateChange = (date : MaterialUiPickersDate) => {
        this.setState({
            event: {
                ...this.state.event,
                EndDate: date?.toLocaleDateString() ?? null
            }
        });
    }

    emojify = (value : string | null) : string | null => {
        if (!value) {
            return value;
        }
        
        let newStr = '';

        value.slice().split('').forEach(x => {
            if (x === '0' || x === '1') {
                newStr += x;
            }
        });


        let newStrArr = newStr.split('');

        let finalStr = '';

        for (let i=0; i<newStrArr.length; i++) {
            finalStr += newStrArr[i];

            if (i > 0 && i%7===6 && i < newStrArr.length - 1) {
                finalStr += '\n';
            }
        }

        finalStr = finalStr.replace(/1/g, '✔️');
        finalStr = finalStr.replace(/0/g, '❌');

        return finalStr;
    }

    demogify = (value : string) : string => {
        if (!value) {
            return value;
        }

        let newStr = value?.slice();

        newStr = newStr.replace(/✔️/g, '1');
        newStr = newStr.replace(/❌/g, '0');
        newStr = newStr.replace(/\n/g, '');

        return newStr;
    }
}