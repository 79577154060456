import { FreedomType } from "../Enums/FreedomType";
import { RepetitionType } from "../Enums/RepetitionType";
import { AsyncServerResponse } from "../Models/AsyncServerResponse";
import { CalendarEvent } from "../Models/CalendarEvent";
import { DayInfo } from "../Models/DayInfo";
import { ValidationResultCollection } from "../Models/ValidationResultCollection";
import { ICalendarService, IDateService, IHttpService } from "./Interfaces/Interfaces";

export class CalendarService implements ICalendarService {
    private readonly httpService : IHttpService;
    private readonly dateService : IDateService;

    constructor(httpService : IHttpService, dateService : IDateService) {
        this.httpService = httpService;
        this.dateService = dateService;
    }

    getCalendarEvents() : AsyncServerResponse<CalendarEvent[]> {
        return this.httpService.httpGet<CalendarEvent[]>('events');
    }

    saveCalendarEvents = (events : CalendarEvent[]) : AsyncServerResponse<ValidationResultCollection> => {
        return this.httpService.httpPost('events', events);
    }
  
    getDayInfo = (date : Date, calendarEvents : CalendarEvent[]) : DayInfo => {
        let dayInfo = new DayInfo(date);
        
        if (!calendarEvents || !calendarEvents.length) {
            return dayInfo;
        }

        const dateMatches = (event : CalendarEvent) => this.dateService.dateMatches(date, event);

        let matchingEvents = calendarEvents.filter(dateMatches).sort((a,b) => (this.getCalendarEventSortOrder(a) > this.getCalendarEventSortOrder(b)) ? 1 : -1);
        let matchingRegularEventIdx = this.getPatternEvents(calendarEvents).findIndex(dateMatches);
        
        if (!matchingEvents || !matchingEvents.length) {
            return dayInfo;
        }
        
        dayInfo.CalendarEvents = matchingEvents;
        dayInfo.FreedomType = this.getFreedomType(matchingEvents[0], date)
        dayInfo.PatternIdx = matchingRegularEventIdx >= 0 ? matchingRegularEventIdx : null;

        return dayInfo;
    }
    
    getPatternEvents(calendarEvents : CalendarEvent[]) : CalendarEvent[] {
        return calendarEvents.filter(x => !x.IsOverride)
    }

    eventMatches(evt1 : CalendarEvent, evt2 : CalendarEvent) : boolean {
        if (evt1.Guid && evt2.Guid) {
            return evt1.Guid === evt2.Guid;
        }

        if (evt1.CalendarEventId && evt2.CalendarEventId) {
            return evt1.CalendarEventId === evt2.CalendarEventId;
        }

        return false;
    }

    validateCalendarEvents(events : CalendarEvent[]) : AsyncServerResponse<ValidationResultCollection> {
        return this.httpService.httpPost<ValidationResultCollection>('validatecalendar', events);
    }

    getUniqueCalendarEventId(event : CalendarEvent) : string {
        let a = event.CalendarEventId ? event.CalendarEventId : '';
        let b = event.Guid ? event.Guid : '';

        return a + b;
    }

    private getCalendarEventSortOrder = (evt : CalendarEvent) => {
        if (evt.IsOverride) {
            if (evt.RepetitionType === RepetitionType.None) {
                return 0;
            }
            else if (evt.RepetitionType === RepetitionType.AnnualByDate) {
                return 1;
            }
            else if (evt.RepetitionType === RepetitionType.AnnualByDayOfWeek) {
                return 2;
            }
        }    
        return 3;
    }
    
    public getFreedomType(event : CalendarEvent, date : Date) {
        if (!event.Pattern) {
            return FreedomType.NoInfo;
        }

        let patternIdx = this.getPatternIdx(event, date);
        const patternArray = event.Pattern.split('').map(x => +x);
        let isFree = !patternArray[patternIdx];

        return isFree ? FreedomType.Free : FreedomType.Busy;
    }
    
    private getPatternIdx(event : CalendarEvent, date : Date) : number {
        if (!event.Pattern) {
            throw new Error('Tried to get pattern idx for null pattern!');
        }

        const startDate = new Date(event.StartDate);
        const pattern = event.Pattern.split('').map(x => +x);

        // If there is no annual repetition, events represent days
        if (event.RepetitionType === RepetitionType.None) {
            let daysElapsed = this.dateService.getDaysElapsed(startDate, date);    
            return (daysElapsed < 0 ? (pattern.length+(daysElapsed%pattern.length)) : daysElapsed%pattern.length) % pattern.length;
        }

        return (Math.abs(new Date(event.StartDate).getFullYear() - date.getFullYear())) % pattern.length;
    }
}