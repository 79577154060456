import { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { DayInfo } from '../../Models/DayInfo';
import TodayIcon from '@material-ui/icons/Today';
import Button from '@material-ui/core/Button';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { CalendarEvent } from '../../Models/CalendarEvent';
import DateRangeIcon from '@material-ui/icons/DateRange';
import './EventChooserDialog.css';

class EventChooserDialogState {
}

class EventChooserDialogProps {
    dayInfo : DayInfo | null = null;
    isOpen = false;
    onClose = () => {};
    onEventSelected : (event : CalendarEvent) => void = () => {};
}

export class EventChooserDialog extends Component<EventChooserDialogProps, EventChooserDialogState> {
    render() {
        if (!this.props.dayInfo) {
            return null;
        }

        return (
            <Dialog className="EventChooserDialog" open={this.props.isOpen} onClose={this.props.onClose}>
                <DialogContent>
                    <DialogContentText>
                        <div>
                            Choose event:
                        </div>
                        <div>
                            {this.props.dayInfo.CalendarEvents.map(x => {
                                return (
                                    <div key={x.CalendarEventId} className="button-area">
                                        <Button
                                            variant="contained"
                                            startIcon={x.IsOverride ? <TodayIcon /> : <DateRangeIcon />}
                                            fullWidth={true}
                                            onClick={() => this.props.onEventSelected(x)}
                                        >
                                            {x.Name}
                                        </Button>
                                    </div>
                                );
                            })}

                            <div className="button-area">
                                <Button 
                                    variant="contained" 
                                    color="secondary" 
                                    startIcon={<AddBoxIcon />} 
                                    fullWidth={true} 
                                    onClick={() => this.props.onEventSelected(new CalendarEvent(this.props.dayInfo?.Date))}
                                >
                                    Create New
                                </Button>
                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}