import { FreedomType } from "../Enums/FreedomType";
import { CalendarEvent } from "./CalendarEvent";

export class DayInfo {
    constructor(date : Date) {
        this.Date = date;
    }
    FreedomType = FreedomType.NoInfo;
    PatternIdx : number | null = null;
    Date : Date;
    CalendarEvents : CalendarEvent[] = [];
    get DefaultEvent() : CalendarEvent | null {
        return this.CalendarEvents.find(() => true) ?? null;
    }
}