import { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { DayInfo } from '../../Models/DayInfo';
import { ReportData } from '../../Models/ReportData';
import { CalendarEvent } from '../../Models/CalendarEvent';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ICalendarService } from '../../Services/Interfaces/Interfaces';
import './ReportDialog.css';
import { FreedomType } from '../../Enums/FreedomType';

class ReportDialogState {
    startDate : string | null = null;
    endDate : string | null = null;
}

class ReportDialogProps {
    isOpen = false;
    onClose = () => {};
    calendarEvents : CalendarEvent[] = [];
    
    calendarService : ICalendarService;

    constructor(calendarService : ICalendarService) {
        this.calendarService = calendarService;
    }
}

export class ReportDialog extends Component<ReportDialogProps, ReportDialogState> {
    constructor(props : ReportDialogProps) {
        super(props);
        this.state = new ReportDialogState();
    }

    render() {
        const format = 'MM/dd/yyyy';
        const inputVariant = 'outlined';

        const datas = this.getReportDatas();

        return (
            <Dialog className="ReportDialog" open={this.props.isOpen} onClose={this.props.onClose}>
                <DialogContent>
                    <DialogContentText>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <div>
                                <DatePicker
                                    disableToolbar
                                    inputVariant={inputVariant}
                                    variant="inline"
                                    format={format}
                                    margin="normal"
                                    id="start-date"
                                    label="Start Date"
                                    value={this.state.startDate}
                                    onChange={this.handleStartDateChange}
                                    autoOk={true}
                                    fullWidth={true}
                                />
                                <DatePicker
                                    disableToolbar
                                    inputVariant={inputVariant}
                                    variant="inline"
                                    format={format}
                                    margin="normal"
                                    id="end-date"
                                    label="End Date"
                                    value={this.state.endDate}
                                    onChange={this.handleEndDateChange}
                                    autoOk={true}
                                    fullWidth={true}
                                />
                            </div>
                        </MuiPickersUtilsProvider>
                        <div>
                            {this.renderReportData('Total Days', datas.total)}
                            {this.renderReportData('Max Days in a Row', datas.max)}
                            {this.renderReportData('Min Days in a Row', datas.min)}
                            {this.renderReportData('Total Weekends', datas.weekends)}
                            {this.renderReportData('Total Mondays', datas.perWeek[1])}
                            {this.renderReportData('Total Tuesdays', datas.perWeek[2])}
                            {this.renderReportData('Total Wednesdays', datas.perWeek[3])}
                            {this.renderReportData('Total Thursdays', datas.perWeek[4])}
                            {this.renderReportData('Total Fridays', datas.perWeek[5])}
                            {this.renderReportData('Total Saturdays', datas.perWeek[6])}
                            {this.renderReportData('Total Sundays', datas.perWeek[0])}
                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }

    renderReportData(title : string, data : ReportData) {
        const display = this.state.startDate && this.state.endDate && data;
        const on = display ? data.on : '?';
        const off = display ? data.off : '?';

        return (
            <div>{title}: <span className="primary-color-text">{on}</span> / <span className="off-text">{off}</span></div>
        );
    }

    handleStartDateChange = (date : MaterialUiPickersDate) => {
        if (date === null) {
            return;
        }
        this.setState({
            startDate: date.toLocaleDateString()
        });
    }

    handleEndDateChange = (date : MaterialUiPickersDate) => {
        if (date === null) {
            return;
        }
        this.setState({
            endDate: date.toLocaleDateString()
        });
    }

    getDaysArray(start : Date, end : Date) {
        for(var arr=[],dt=new Date(start); dt<=end; dt.setDate(dt.getDate()+1)){
            arr.push(new Date(dt));
        }
        return arr;
    };

    getReportDatas() {
        let total = new ReportData();
        let max = new ReportData();
        let min = new ReportData();
        let weekends = new ReportData();
        let perWeek = [ 
            new ReportData(), 
            new ReportData(), 
            new ReportData(), 
            new ReportData(), 
            new ReportData(), 
            new ReportData(), 
            new ReportData() 
        ];

        let datas = {
            total,
            max,
            min,
            weekends,
            perWeek
        };

        if (!this.state.startDate || !this.state.endDate) {
            return datas;
        }

        let dates = this.getDaysArray(new Date(this.state.startDate), new Date(this.state.endDate));
        let prevDayInfo : DayInfo | null = null;

        dates.forEach((x, i) => {
            let dayInfo = this.props.calendarService.getDayInfo(x, this.props.calendarEvents);

            if (!dayInfo.DefaultEvent) {
                return;
            }

            if (dayInfo.FreedomType === FreedomType.Busy) {
                datas.total.on++;
                let day = dayInfo.Date.getDay();
                datas.perWeek[day].on++;
                if (day === 6 && prevDayInfo && prevDayInfo.FreedomType === FreedomType.Busy) {
                    weekends.on++;
                }
                let currentInARow=1;
                if (datas.min.on === 0) {
                    datas.min.on = 1;
                }
                for (let j=i+1; j<dates.length; j++) {
                    let next = this.props.calendarService.getDayInfo(dates[j], this.props.calendarEvents);
                    if (next.FreedomType === FreedomType.Busy) {
                        currentInARow++;
                    }
                    else {
                        break;
                    }
                }
                if (currentInARow > datas.max.on) {
                    datas.max.on = currentInARow;
                }
                if (currentInARow < datas.min.on) {
                    datas.min.on = currentInARow;
                }
            }
            else if (dayInfo.FreedomType === FreedomType.Free) {
                datas.total.off++;
                let day = dayInfo.Date.getDay();
                datas.perWeek[day].off++;
                if (day === 6 && prevDayInfo && prevDayInfo.FreedomType === FreedomType.Free) {
                    weekends.off++;
                }
                let currentInARow=1;
                if (datas.min.off === 0) {
                    datas.min.off = 1;
                }
                for (let j=i+1; j<dates.length; j++) {
                    let next = this.props.calendarService.getDayInfo(dates[j], this.props.calendarEvents);
                    if (next.FreedomType === FreedomType.Free) {
                        currentInARow++;
                    }
                    else {
                        break;
                    }
                }
                if (currentInARow > datas.max.off) {
                    datas.max.off = currentInARow;
                }
                if (currentInARow < datas.min.off) {
                    datas.min.off = currentInARow;
                }
            }

            prevDayInfo = dayInfo;
        });

        return datas;
    }
}