import './App.css';
import 'react-infinite-calendar/styles.css';
import { Component, RefObject, createRef } from 'react';
import { CalendarService } from './Services/CalendarService';
import { AuthorizationService } from './Services/AuthorizationService';
import { UserAccessType } from './Enums/UserAccessType';
import { MainCalendar } from './Components/MainCalendar/MainCalendar';
import { LoginDialog } from './Components/LoginDialog/LoginDialog';
import { CircularProgress, createMuiTheme, ThemeProvider } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { Backdrop } from '@material-ui/core';
import { CalendarEvent } from './Models/CalendarEvent';
import { HttpService } from './Services/HttpService';
import { DateService } from './Services/DateService';
import { withStyles, Theme } from '@material-ui/core/styles';
import { Settings } from './Settings/Settings';
import { ICalendarService, IHttpService, IAuthorizationService, IDateService } from './Services/Interfaces/Interfaces';

class AppState {
  windowHeight : number = window.innerHeight;
  windowWidth : number = window.innerWidth;
  userAccessType : UserAccessType = UserAccessType.None;
  isLoginDialogOpen = false;
  calendarEvents : CalendarEvent[] = [];
  lastLoadedCalendarEventJson : string | null = null;
  isSaved = true;
  isCalendarLoading = false;
}

const styles = (theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
});

class App extends Component<any, AppState> {
  private readonly loginDialog : RefObject<LoginDialog>;
  private readonly animationDelay = 500;
  
  private readonly services : {
    calendarService : ICalendarService,
    httpService : IHttpService,
    authorizationService : IAuthorizationService,
    dateService : IDateService
  };

  constructor(props : any) {
    super(props);

    this.state = new AppState();
    this.loginDialog = createRef(); 

    const httpService = new HttpService();
    const dateService = new DateService();

    const authorizationService = new AuthorizationService(httpService);
    const calendarService = new CalendarService(httpService, dateService);

    this.services = {
      calendarService,
      authorizationService,
      httpService,
      dateService
    };
  }

  componentDidMount() {
    if (window.location.href.endsWith('//')) {
      window.location.href = window.location.href.slice(0, window.location.href.length-1);
    }

    window.addEventListener('resize', this.handleResize)

    if (Settings.IS_DEBUG) {
      window.document.title = window.document.title = 'IsBrieFree.com (DEV)';
    }
    
    setTimeout(() => {
      this.setState({isLoginDialogOpen: true});
    }, this.animationDelay);
  }

  render() {
    const theme = createMuiTheme({
      palette: {
        primary: {
          main: '#40E0D0',
          contrastText: '#FFF'
        },
        secondary: {
          main: blue[500],
        }
      },
    });

    const { classes } = this.props;

    return (
      <div className="App">
        <ThemeProvider theme={theme}>
          <Backdrop className={classes.backdrop} open={this.state.isCalendarLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          {this.state.userAccessType !== UserAccessType.None &&
            <MainCalendar 
              windowWidth={this.state.windowWidth} 
              windowHeight={this.state.windowHeight} 
              calendarEvents={this.state.calendarEvents}
              userAccessType={this.state.userAccessType}
              onEventSaved={this.handleEventSaved}
              onEventDeleted={this.handleEventDeleted}
              onRefreshCalendar={this.populateCalendarEvents}
              onCalendarSaved={this.handleCalendarSaved}
              dateService = {this.services.dateService}
              calendarService = {this.services.calendarService}
              isSaved = {this.state.isSaved}
            />
          }
          <LoginDialog 
            ref={this.loginDialog} 
            isOpen={this.state.isLoginDialogOpen} 
            onClose={() => { this.setState({isLoginDialogOpen: false}); } } 
            onSubmit={passcode => this.logIn(passcode)}
          />
        </ThemeProvider>
      </div>
    );
  }

  logIn = (token : string) => {
    this.services.authorizationService.logInAndGetToken(token).then(x => {
      if (!x.IsSuccess) {
        console.error(x.Message);
        this.loginDialog.current?.onLoginFailed();
      }

      if (!x.Data) {
        return;
      }

      this.services.httpService.setToken(x.Data.Token);

      this.setState({
        userAccessType: x.Data.UserAccessType,
        isLoginDialogOpen: false,
      }, this.populateCalendarEvents);
    });
  }

  handleCalendarSaved = () => {
    this.setState({
      isCalendarLoading: true
    });
    this.services.calendarService.saveCalendarEvents(this.state.calendarEvents).then(result => {
      if (!result.IsSuccess) {
        console.error(result.Message);
        alert('An error occured while saving.');
      }
      else {
        this.setState({
          isSaved: true,
        });
      }

      this.setState({
        isCalendarLoading: false
      });
    });
  }

  handleEventSaved = (event : CalendarEvent) => {
    let events = this.state.calendarEvents;

    let oldEventsJson = JSON.stringify(events);

    let matchingEventIdx = events.findIndex(x => this.services.calendarService.eventMatches(x, event));

    if (matchingEventIdx >= 0) {
      events.splice(matchingEventIdx, 1);
    }

    events.push(event);

    let newEventsJson = JSON.stringify(events);

    let isModified = oldEventsJson === newEventsJson;

    this.setState({
      calendarEvents: events,
      isSaved: isModified
    });
  }

  handleEventDeleted = (event : CalendarEvent) => {
    let events = this.state.calendarEvents;

    let matchingEventIdx = events.findIndex(x => this.services.calendarService.eventMatches(x, event));

    if (matchingEventIdx >= 0) {
      events.splice(matchingEventIdx, 1);
    }

    this.setState({
      calendarEvents: events,
      isSaved: JSON.stringify(events) === this.state.lastLoadedCalendarEventJson
    });
  }

  populateCalendarEvents = () => {
    this.setState({
      isCalendarLoading : true
    });

    this.services.calendarService.getCalendarEvents().then(x => {
      if (!x.Data) {
        return;
      }

      this.setState({
        calendarEvents: x.Data,
        isCalendarLoading : false,
        isSaved : true,
        lastLoadedCalendarEventJson: JSON.stringify(x.Data),
      });
    });
  }

  handleResize = () => {
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth
    });
  }
}

export default withStyles(styles)(App);