import React, { ChangeEvent, Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faTimes } from '@fortawesome/free-solid-svg-icons';
import ClipLoader from "react-spinners/ClipLoader";
import './LoginDialog.css';

class LoginDialogState {
    passcode = '';
    hasInvalidPassword = false;
    isBusy = false;
}

class LoginDialogProps {
    isOpen = false;
    onClose = () => {};
    onSubmit = (passcode : string) => {}
}

export class LoginDialog extends Component<LoginDialogProps, LoginDialogState> {
    constructor(props : LoginDialogProps) {
        super(props);

        this.state = new LoginDialogState();
    }

    readonly animationDelay = 500;

    render() {        
        const icon = this.state.hasInvalidPassword ? faTimes : faLock;
        return (
            <div>
                <Dialog className="login-dialog" open={this.props.isOpen} onClose={this.handleClose} disableBackdropClick>
                    <DialogContent>
                        <DialogContentText>
                            Enter passcode please!
                        </DialogContentText>
                        <TextField
                            autoFocus
                            name="passcode"
                            id="name"
                            variant="outlined"
                            label="🔑"
                            type="password"
                            inputProps={{ 
                                min: 0, 
                                style: { 
                                    textAlign: 'center' 
                                },
                                maxLength: 4,
                            }}
                            value={this.state.passcode}
                            disabled={this.state.isBusy}
                            onChange={this.handleChange}
                        />
                        <div className="unlock-status">
                            {this.state.isBusy &&
                                <ClipLoader loading={this.state.isBusy} color="#4287f5" size={22} />
                            }
                            {!this.state.isBusy &&
                                <FontAwesomeIcon icon={icon} />
                            }
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }

    onLoginFailed = () => {
        this.setState({
            passcode: '',
            hasInvalidPassword: true,
            isBusy: false
        });
    }

    handleClose = () => {
        this.props.onClose();
    }

    handleChange = (e : ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;

        this.setState({passcode: val});

        if (val && val.length >= 4) {
            this.setState({isBusy: true});
            setTimeout(() => {
                this.props.onSubmit(val);
            }, this.animationDelay);
        }
    }
}


