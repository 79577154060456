import InfiniteCalendar from 'react-infinite-calendar';
import { Component } from 'react';
import $ from "jquery";
import { CalendarEvent } from '../../Models/CalendarEvent';
import { FreedomType } from '../../Enums/FreedomType';
import { DayInfo } from '../../Models/DayInfo';
import { EventDialog } from '../EventDialog/EventDialog';
import { EventChooserDialog } from '../EventChooserDialog/EventChooserDialog';
import { ReportDialog } from '../ReportDialog/ReportDialog';
import { UserAccessType } from '../../Enums/UserAccessType';
import { ICalendarService, IDateService } from '../../Services/Interfaces/Interfaces';
import RefreshIcon from '@material-ui/icons/Refresh';
import BarChartIcon from '@material-ui/icons/BarChart';
import SaveIcon from '@material-ui/icons/Save';
import { AlertDialog } from '../AlertDialog/AlertDialog';
import { AlertDialogResponse } from '../../Enums/AlertDialogResponse';
import 'react-infinite-calendar/styles.css';
import './MainCalendar.css';

class MainCalendarState {
  isEventDialogOpen = false;
  isEventChooserDialogOpen = false;
  isConfirmRefreshOpen = false;
  isConfirmSaveCalendarOpen = false;
  isReportDialogOpen = false;
  selectedDayInfo : DayInfo | null = null;
  selectedEvent : CalendarEvent | null = null;
}

export class MainCalendarProps {
    windowHeight : number = window.innerHeight;
    windowWidth : number = window.innerWidth;
    calendarEvents : CalendarEvent[] = [];
    userAccessType : UserAccessType = UserAccessType.None;
    isSaved = true;

    onEventSaved : (event : CalendarEvent) => void = () => {};
    onEventDeleted : (event : CalendarEvent) => void = () => {};
    onRefreshCalendar : () => void = () => {};
    onCalendarSaved : () => void = () => {};

    dateService : IDateService;
    calendarService : ICalendarService;

    constructor(dateService : IDateService, calendarService : ICalendarService) {
      this.dateService = dateService;
      this.calendarService = calendarService;
    }
}

export class MainCalendar extends Component<MainCalendarProps, MainCalendarState> {
  constructor(props : MainCalendarProps) {
    super(props);
    
    this.state = new MainCalendarState();
  }

  readonly animationDelay = 100;

  componentDidMount() {
    this.addCalendarColors();
  }

  componentDidUpdate() {
    this.addCalendarColors();
  }

  render() {
    let today = new Date();
    const headerHeight = 147;
    const windowHeight = this.props.windowHeight;
    const windowWidth = this.props.windowWidth;
    const key = this.state.selectedEvent ? this.props.calendarService.getUniqueCalendarEventId(this.state.selectedEvent) : '';
    return (
      <div className="MainCalendar">
          <div className="header-icons">
            { !this.props.isSaved &&
              <SaveIcon onClick={this.handleSaveCalendarClicked}/>
            }
            <BarChartIcon onClick={() => this.setState({isReportDialogOpen: true}) } />
            <RefreshIcon onClick={this.handleRefreshClicked} />
          </div>
          <InfiniteCalendar
            selected={today}
            onScroll={this.addCalendarColors}
            height={windowHeight - headerHeight}
            rowHeight={Math.min(windowWidth/windowHeight, 1.25)*100}
            onSelect={this.handleDateSelected}
            locale={{weekStartsOn:1}}
          />
          <EventDialog 
            key={key} 
            allEvents={this.props.calendarEvents}
            event={this.state.selectedEvent} 
            isOpen={this.state.isEventDialogOpen} 
            onClose={this.handleEventDialogClose} 
            onSave={this.handleEventSaved}
            onDelete={this.handleEventDeleted}
            calendarService={this.props.calendarService}
          />
          <EventChooserDialog 
            onEventSelected={this.handleEventSelected} 
            dayInfo={this.state.selectedDayInfo} 
            isOpen={this.state.isEventChooserDialogOpen} 
            onClose={this.handleEventChooserDialogClose} 
          />
          <ReportDialog isOpen={this.state.isReportDialogOpen} onClose={() => this.setState({isReportDialogOpen: false}) } calendarEvents={this.props.calendarEvents} calendarService={this.props.calendarService} />
          <AlertDialog isOpen={this.state.isConfirmRefreshOpen} onClose={this.handleConfirmRefresh} isConfirm={true} message="Are you sure you want to discard unsaved changes?" />
          <AlertDialog isOpen={this.state.isConfirmSaveCalendarOpen} onClose={this.handleSaveCalendarConfirm} isConfirm={true} message="Are you suuure you want to save?" />
      </div>
    );
  }

  handleSaveCalendarConfirm = (response? : AlertDialogResponse) => {
    if (response === AlertDialogResponse.OK) {
      this.props.onCalendarSaved();
    }

    this.setState({
      isConfirmSaveCalendarOpen: false
    });
  }

  handleSaveCalendarClicked = () => {
    this.setState({
      isConfirmSaveCalendarOpen: true
    });
  }

  handleConfirmRefresh = (response? : AlertDialogResponse) => {
    if (response === AlertDialogResponse.OK) {
      this.props.onRefreshCalendar();
    }

    this.setState({
      isConfirmRefreshOpen: false
    });
  }

  handleRefreshClicked = () => {
    if (!this.props.isSaved) {
      this.setState({
        isConfirmRefreshOpen: true
      });
    }
    else {
      this.props.onRefreshCalendar();
    }
  }

  handleEventSelected = (event : CalendarEvent) => {
    this.handleEventChooserDialogClose();
    setTimeout(() => {
      this.setState({ 
        isEventDialogOpen: true,
        selectedEvent: event
      });
    }, this.animationDelay);
  }

  handleEventSaved = (event : CalendarEvent) => {
    this.props.onEventSaved(event);
    this.handleEventDialogClose();
  }

  handleEventDeleted = (event : CalendarEvent) => {
    this.props.onEventDeleted(event);
    this.handleEventDialogClose();
  }

  handleEventDialogClose = () => {
    this.setState({ isEventDialogOpen: false });
  }

  handleEventChooserDialogClose = () => {
    this.setState({
      selectedEvent : null,
      isEventChooserDialogOpen: false
    });
  }

  handleDateSelected = (date : Date) => {
    if ($('.Cal__Years__root').length) {
      // a date was clicked from the year view. ignore
      return;
    }

    let dayInfo = this.props.calendarService.getDayInfo(date, this.props.calendarEvents);

    this.setState({selectedDayInfo: dayInfo});

    if (this.props.userAccessType !== UserAccessType.Admin) {
      return;
    }
    
    if (dayInfo.CalendarEvents.length > 0) {
      this.setState({
        isEventChooserDialogOpen: true
      });
    }
    else {
      this.setState({
        isEventDialogOpen: true,
        selectedEvent: new CalendarEvent(date),
      });
    }
  }

  addCalendarColors = () => {
    if (!this.props.calendarEvents || !this.props.calendarEvents.length) {
      return;
    }

    let elements = $('li[class*="Cal__Day__root"]');
    const colors = [
      '64,224,208',
      '123,224,64',
    ];

    const overrideColors = [
      '255,255,255',
      '255,0,0'
    ];

    const today = new Date();
    const todaysPatternIdx = this.props.calendarService.getPatternEvents(this.props.calendarEvents).findIndex(x => !x.IsOverride && this.props.dateService.addDays(new Date(x.StartDate), -1) <= today && (!x.EndDate || new Date(x.EndDate) >= today));

    $.each(elements, (_i,e) => {
      let dateStr = $(e).data('date');
      let dateSplit = dateStr.split('-');
      
      var date = new Date(dateSplit[0],dateSplit[1]-1,dateSplit[2]);

      let dayInfo = this.props.calendarService.getDayInfo(date, this.props.calendarEvents);

      let bgColor = '218,165,32';
      let freedomColor = '255,255,255';
      let unknownColor = '150,150,150';
      let overrideExistsThatChangesFreedom = false;

      if (dayInfo.DefaultEvent?.IsOverride) {
        let patternEvent = dayInfo.CalendarEvents.find(x => !x.IsOverride);
        if (patternEvent) {
          let patternFreedomType = this.props.calendarService.getFreedomType(patternEvent, date);
          overrideExistsThatChangesFreedom = patternFreedomType !== dayInfo.FreedomType;
        }
        else {
          overrideExistsThatChangesFreedom = true;
        }
      }

      if (dayInfo.DefaultEvent && dayInfo.FreedomType === FreedomType.Busy) {
        if (dayInfo.PatternIdx !== null) {
          let colorIdx = (dayInfo.PatternIdx - todaysPatternIdx) % colors.length;
          if (colorIdx < 0) {
            colorIdx = (colors.length) + colorIdx;
          }
          bgColor = colors[colorIdx];
        }
        else {
          bgColor = colors[0];
        }
      }
      else if (dayInfo.FreedomType === FreedomType.Free) {
        bgColor = freedomColor;
      }
      else {
        bgColor = unknownColor;
      }

      if (dayInfo.DefaultEvent?.IsOverride) {
        let fixedBgColor = bgColor === '255,255,255' ? '200,200,200' : bgColor;
        let fixedBgSplit = fixedBgColor.split(',');
        let newBgColor = '';
        const dimmerAmt = 50;
        fixedBgSplit.forEach((x,i) => {
          let curColor = (+x) - dimmerAmt;
          if (curColor < 0) {
            curColor = 0;
          }
          
          newBgColor += curColor.toString();

          if (i < fixedBgSplit.length-1) {
            newBgColor += ',';  
          }
        });
        
        let overrideBoxColor = overrideExistsThatChangesFreedom ? overrideColors[1] : newBgColor;

        $(e).css('box-shadow', `inset 0px 0px 0px 4px rgba(${overrideBoxColor},1)`);
      }
      else {
        $(e).css('box-shadow', 'none');
      }

      $(e).css('background-color', `rgba(${bgColor},.5)`);

    });
  }
}