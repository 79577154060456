import { Component } from "react";
import { AlertDialogResponse } from "../../Enums/AlertDialogResponse";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

class AlertDialogState {
}

class AlertDialogProps {
    message? : string;
    messages? : string[] = [];
    isConfirm? : boolean = false;
    isOpen : boolean = false;
    onClose : (response? : AlertDialogResponse) => void = () => {};
}

export class AlertDialog extends Component<AlertDialogProps, AlertDialogState> {
    render() {
        return (
            <div className="AlertDialog">
                <Dialog
                    open={this.props.isOpen}
                    onClose={this.handleClose}
                    disableBackdropClick={this.props.isConfirm}
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.props.message &&
                                <div>{this.props.message}</div>
                            }

                            {this.props.messages?.map((x,i) => 
                                <div key={i}>{x}</div>
                            )}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleOk} color="secondary" variant="contained">
                            {!this.props.isConfirm ? 'OK' : 'Yes'}
                        </Button>
                        {this.props.isConfirm &&
                            <Button onClick={this.handleCancel} variant="contained" autoFocus>
                                No
                            </Button>
                        }
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    handleClose = () => {
        this.props.onClose();
    }

    handleOk = () => {
        this.props.onClose(AlertDialogResponse.OK);
    }

    handleCancel = () => {
        this.props.onClose(AlertDialogResponse.Cancel);
    }
}