import 'react-infinite-calendar/styles.css';
import { CalendarEvent } from '../Models/CalendarEvent';
import { RepetitionType } from '../Enums/RepetitionType';
import { IDateService } from './Interfaces/Interfaces';

export class DateService implements IDateService {
  dateMatches = (date : Date, event : CalendarEvent) : boolean => {
    const startDate = new Date(event.StartDate);
    const endDate = event.EndDate ? new Date(event.EndDate) : null;

    if (date < startDate || (endDate && date > endDate)) {
      return false;
    }

    const startDateMatchesDate = event.IsOverride ? (startDate.getFullYear() === date.getFullYear() && startDate.getMonth() === date.getMonth() && startDate.getDate() === date.getDate()) : startDate <= date && (!endDate || endDate >= date);

    if (startDateMatchesDate || event.RepetitionType === RepetitionType.None) {
      return (startDateMatchesDate || (startDate <= date)) && (!endDate || endDate >= date);
    }

    const matchingYear = date.getFullYear();

    if (event.RepetitionType === RepetitionType.AnnualByDate) {
      const matchingYearStartDate = new Date(startDate);

      matchingYearStartDate.setFullYear(matchingYear);
      
      const relativeMatch = matchingYearStartDate.toLocaleDateString() === date.toLocaleDateString() && (!endDate || endDate >= date);

      return relativeMatch;
    }

    if (event.RepetitionType === RepetitionType.AnnualByDayOfWeek) {
      let relativeByDayOfWeekResult = this.relativeByDayOfWeekMatches(startDate, date);

      return relativeByDayOfWeekResult;
    }

    return false;
  }

  relativeByDayOfWeekMatches(startDate : Date, date : Date) : boolean {
    const weekNumber = Math.floor((startDate.getDate()-1)/7)+1;

    let relativeDateForYear = this.getRelativeDate(date.getFullYear(), startDate.getMonth(), weekNumber, startDate.getDay());

    const match = relativeDateForYear.getFullYear() === date.getFullYear() && relativeDateForYear.getMonth() === date.getMonth() && relativeDateForYear.getDate() === date.getDate();

    return match;
  }

  getRelativeDate(year : number, month : number, weekNumber : number, dayOfWeek : number) : Date {
    let firstDay = new Date(year, month, 1);
    let dayOffset = firstDay.getDay();
    
    let date = 7 * (weekNumber - 1) + dayOfWeek - dayOffset + 1;

    let currentWeekNumber = Math.floor((date-1)/7)+1;

    if (currentWeekNumber < weekNumber) {
      date += 7;
    }
    else if (currentWeekNumber < weekNumber) {
      date -= 7;
    }

    let newDate = new Date(year, month, date);

    return newDate;
  }

  getDaysElapsed(first : Date, second : Date) : number {
    return Math.round((second.getTime()-first.getTime())/(1000*60*60*24));
  }

  addDays = (date : Date, days : number) : Date => {
    let newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  }
}